import { useTranslation } from 'react-i18next';
import styles from './Benefits.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock } from '@fortawesome/free-regular-svg-icons';
import { faCoins } from '@fortawesome/free-solid-svg-icons';
import { faHandshake } from '@fortawesome/free-solid-svg-icons';

type Props = {
  className?: string;
};

const Benefits: React.FC<Props> = (props) => {
  const { t } = useTranslation();

  return (
    <section id='benefits' className={styles.benefits}>
      <div className='container pt-5 pb-5'>
        <div className='row justify-content-between'>
          <div className='col-md-6 offset-md-3 align-self-center'>
            <div className='mb-5 pb-md-2'>
              <h2 className='text-center section-heading font-italic mb-5'>
                Korzyści
              </h2>
              <p className='p-heading text-center'>
                Dzięki mojej wiedzy i współpracy z bankami możesz uzyskać korzystniejszą ofertę, uniknąć błędów formalnych i przejść przez cały proces bez stresu.
              </p>
            </div>
          </div>
        </div>
        <div className='row justify-content-between'>
          <div className='col-md-6 offset-md-3 align-self-center'> 
            <div className='row justify-content-center'>
              <div className='col-2 align-items-end'>
                <FontAwesomeIcon icon={faClock} size='2x' />
              </div>
              <div className='col-10'>
                <h5><b>Oszczędność czasu</b></h5>
                <p>Porównam dla Ciebie najlepsze oferty w kilka minut</p>
              </div>
            </div>
            <div className='row justify-content-center'>
              <div className='col-2 align-items-end'>
                <FontAwesomeIcon icon={faCoins} size='2x' />
              </div>
              <div className='col-10'>
                <h5><b>Niższe koszty</b></h5>
                <p>Mam dostęp do ofert niedostępnych w bankach dla zwykłych klientów</p>
              </div>
            </div>
            <div className='row justify-content-center'>
              <div className='col-2 align-items-end'>
                <FontAwesomeIcon icon={faHandshake} size='2x' />
              </div>
              <div className='col-10'>
                <h5><b>Wsparcie na każdym etapie</b></h5>
                <p>Pomagam w całym procesie od analizy zdolności kredytowej po podpisanie umowy</p>
              </div>
            </div>

          </div>
        </div>
      </div>
    </section>
  );
};

export default Benefits;
