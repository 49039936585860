import { Trans, useTranslation } from 'react-i18next';
import styles from './About.module.scss';

type Props = {
  className?: string;
};

const About: React.FC<Props> = (props) => {
  const { t } = useTranslation();

  return (
    <section id='about' className={styles.about}>
      <div className='container py-5'>
        <div className='row justify-content-between'>
          <div className='col-lg-8 offset-lg-2 align-self-center'>
            <div>
              <h2 className='text-center section-heading font-italic mb-5'>
                <Trans t={t} i18nKey='Menu.About'/>
              </h2>
              <p className='p-heading text-justify'>
                <Trans t={t} i18nKey='About.section1'/>
              </p>
              <p className='p-heading text-justify'>
               <Trans t={t} i18nKey='About.section2'/>
              </p>
              <p className='p-heading text-justify'>
                <Trans t={t} i18nKey='About.section3'/>
              </p>
            </div>
            <div className='row justify-content-between'>
              <div className='col-sm-4 text-center mt-4'>
                <div className={styles.feature}>
                  <div className={styles.title}>19 lat</div>
                  <div className={styles.subtitle}>doświadczenia</div>
                  <div className={styles.description}>Działam w branży<br/>od 2006 roku</div>
                </div>      
              </div>
              <div className='col-sm-4 text-center mt-4'>
                <div className={styles.feature}>
                  <div className={styles.title}>460</div>
                  <div className={styles.subtitle}>kredytów hipotecznych</div>
                  <div className={styles.description}>Liczba udzielonych<br/>kredytowych hipotecznych</div>
                </div>      
              </div>
              <div className='col-sm-4 text-center mt-4'>
                <div className={styles.feature}>
                  <div className={styles.title}>96%</div>
                  <div className={styles.subtitle}>skuteczności</div>
                  <div className={styles.description}>Procent pozytywnie<br/>rozpatrzonych wniosków<br/>kredytowych</div>
                </div>      
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
